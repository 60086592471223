export const AppSettings = {
    ProdVersion: '3.3.2.3 Build(09202401)',
    graphAPIhost: 'https://graph.microsoft.com',
     //eslint-disable-next-line
    BasicAuthBase64String: 'YXkkYTUlJmp3cnRtbmg7bGFzamRmOTg3ODc0NTY6YWJjQDk4Nzk3aGprYXMkJmFzZCgqJCUxMjM0',
    graphAPIVersion: 'v1.0',
    InValidChars: {
        //eslint-disable-next-line
        Group: /[;@()!#$%&’*+/=?^`\[{|}\]–,:.~<>áÁàÀâÂäÄãÃçÇéÉèÈêÊíÍîÎñÑóÓôÔöÖßúÚüÜûÛ¿¡°©®™'"\\\u{0080}-\u{FFFF}]/gu,
        Channel: /[~#%&*{}+/:<>?áÁàÀâÂäÄãÃçÇéÉèÈêÊíÍîÎñÑóÓôÔöÖßúÚüÜûÛ¿¡°©®™|'"..\\]/,
        //eslint-disable-next-line
        Email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
        //eslint-disable-next-line
        Domain: /[;@()!#$%&’*+/=?^`\[{|}\]:~<>áÁàÀâÂäÄãÃçÇéÉèÈêÊíÍîÎñÑóÓôÔöÖßúÚüÜûÛ¿¡°©®™'"\\]/g,
        ValidDomain: /^((?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9])(,(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9])*$/,
        HTMLTags: /(<([^>]+)>)/ig
    },
}