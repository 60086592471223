import { getItemInLocalStorage, setItemInLocalStorage } from '../helpers/storage'

const initialState = {
    language: getItemInLocalStorage('language') || 'en'
};

const app = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_MS_CONTEXT':
            const stateToModify = { ...state }
            stateToModify.language = action.payload.context ? action.payload.context.locale : stateToModify.language;
            setItemInLocalStorage('language', stateToModify.language)
            return Object.assign({}, stateToModify, { context: action.payload.context });
        case 'GET_LANGUAGE':
            const language = action.payload.language
            setItemInLocalStorage('language', language)
            return Object.assign({}, state, { language: language });
        default:
            return state
    }
}

export default app