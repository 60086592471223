import React from "react";
import { connect } from "react-redux";
import { Nav } from "office-ui-fabric-react/lib/";
import { transformLinks } from "../../helpers/Helper";
import { Panel, PanelType } from "office-ui-fabric-react/lib/Panel";

class SideNav extends React.Component {
  toggleMenu = () => {
    this.props.toggleMenu();
  };

  render() {
    const { isSmallDevice, showMenu, toggleMenu } = this.props;
    let navLinks = [
      {
        links: [
          {
            name: "Settings",
            url: "",
            show: ["Admin", "User"],
            links: [
              {
                name: "Get Started",
                url: "/#",
                key: "key1",
                icon: "Play",
                show: ["Admin", "User"],
              },
              {
                name: "Documentation",
                url: "/#Documentation",
                key: "key2",
                icon: "Documentation",
                show: ["Admin", "User"],
              },
              {
                name: "Video Training",
                url: "/#Videos",
                key: "key3",
                icon: "Video",
                show: ["Admin", "User"],
              },
              {
                name: "Builds",
                url: "/#Builds",
                key: "key4",
                icon: "Build",
                show: ["Admin"],
              },
              {
                name: "Create ticket",
                url: "/#CreateTicket",
                key: "key5",
                icon: "Phone",
                show: ["Admin", "User"],
              },
            ],
            isExpanded: true,
          },
          {
            name: "Information",
            url: "",
            key: "key6",
            className: "information",
            show: ["Admin", "User"],
            links: [
              {
                name: "Roadmap",
                url: "/#Roadmap",
                key: "key7",
                icon: "MapLayers",
                show: ["Admin", "User"],
              },
              {
                name: "Updates",
                url: "/#Updates",
                key: "key8",
                icon: "PostUpdate",
                show: ["Admin", "User"],
              },
              {
                name: "Ideas",
                url: "/#Ideas",
                key: "key9",
                icon: "HintText",
                show: ["Admin", "User"],
              },
              {
                name: "More Products",
                url: "/#Products",
                key: "key10",
                icon: "ProductList",
                show: ["Admin", "User"],
              },
            ],
            isExpanded: true,
          },
        ],
      },
    ];
    let sideBarLinks =
      this.props.context.role === "admin"
        ? transformLinks(navLinks[0].links, "Admin")
        : transformLinks(navLinks[0].links, "User");
    return (
      <div className="sideNav">
        {isSmallDevice ? (
          <Panel
            isLightDismiss
            isOpen={showMenu}
            onDismiss={toggleMenu}
            hasCloseButton
            type={PanelType.smallFixedNear}
          >
            <Nav groups={sideBarLinks} onLinkClick={toggleMenu} />
          </Panel>
        ) : (
          <Nav groups={sideBarLinks} />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  context: state.app.context,
});
export default connect(mapStateToProps, null)(SideNav);
