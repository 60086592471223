import React, { Component } from "react";
import { connect } from "react-redux";

class Header extends Component {
  render = () => {
    const { title } = this.props;
    return (
      <div className="topHeader">
        <h2>{title ? `Help Center - ${title}` : `Help Center`}</h2>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  context: state.app.context,
});

export default connect(mapStateToProps, null)(Header);
