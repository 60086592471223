import React from "react";
import { Route, Switch } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import Loadable from "react-loadable";

const ErrorPage = Loadable({
  loader: () => import("../src/containers/ErrorPage/ErrorPage"),
  loading() {
    return <p></p>;
  },
});
const Home = Loadable({
  loader: () => import("../src/containers/Settings/Home"),
  loading() {
    return <p></p>;
  },
});
const Documentation = Loadable({
  loader: () => import("../src/containers/Settings/Documentation"),
  loading() {
    return <p></p>;
  },
});
const Videos = Loadable({
  loader: () => import("../src/containers/Settings/Videos"),
  loading() {
    return <p></p>;
  },
});
const Builds = Loadable({
  loader: () => import("../src/containers/Settings/Builds"),
  loading() {
    return <p></p>;
  },
});
const CreateTicket = Loadable({
  loader: () => import("../src/containers/Settings/CreateTicket"),
  loading() {
    return <p></p>;
  },
});
const Roadmap = Loadable({
  loader: () => import("../src/containers/Information/Roadmap"),
  loading() {
    return <p></p>;
  },
});
const Ideas = Loadable({
  loader: () => import("../src/containers/Information/Ideas"),
  loading() {
    return <p></p>;
  },
});
const Updates = Loadable({
  loader: () => import("../src/containers/Information/Updates"),
  loading() {
    return <p></p>;
  },
});
const Products = Loadable({
  loader: () => import("../src/containers/Information/Products"),
  loading() {
    return <p></p>;
  },
});
const WhatsNew = Loadable({
  loader: () => import("../src/containers/Information/WhatsNew"),
  loading() {
    return <p></p>;
  },
});
export const Routes = (props) => {
  const { isSmallDevice } = props;
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={(params) => (
          <Layout {...params} isSmallDevice={isSmallDevice}>
            <Home history={params.history} />
          </Layout>
        )}
      />
      <Route
        exact
        path="/Documentation"
        render={(params) => (
          <Layout {...params} title="Documentation" isSmallDevice={isSmallDevice}>
            <Documentation></Documentation>
          </Layout>
        )}
      />
      <Route
        exact
        path="/Videos"
        render={(params) => (
          <Layout {...params} title="Video Training" isSmallDevice={isSmallDevice}>
            <Videos {...params} />
          </Layout>
        )}
      />
      <Route
        exact
        path="/Builds"
        render={(params) => (
          <Layout {...params} title="Builds" isSmallDevice={isSmallDevice}>
            <Builds {...params} />
          </Layout>
        )}
      />
      <Route
        exact
        path="/CreateTicket"
        render={(params) => (
          <Layout {...params} title="Submit a ticket" isSmallDevice={isSmallDevice}>
            <CreateTicket {...params} />
          </Layout>
        )}
      />
      <Route
        exact
        path="/Roadmap"
        render={(params) => (
          <Layout {...params} title="Roadmap" isSmallDevice={isSmallDevice}>
            <Roadmap {...params} />
          </Layout>
        )}
      />
      <Route
        exact
        path="/Ideas"
        render={(params) => (
          <Layout {...params} title="Ideas" isSmallDevice={isSmallDevice}>
            <Ideas {...params} />
          </Layout>
        )}
      />
      <Route
        exact
        path="/Updates"
        render={(params) => (
          <Layout {...params} title="Latest Updates" isSmallDevice={isSmallDevice}>
            <Updates {...params} />
          </Layout>
        )}
      />
      <Route
        exact
        path="/Products"
        render={(params) => (
          <Layout {...params} title="Other Products" isSmallDevice={isSmallDevice}>
            <Products {...params} />
          </Layout>
        )}
      />
       <Route
        exact
        path="/WhatsNew"
        render={(params) => (
          <Layout {...params} title="What's New" isSmallDevice={isSmallDevice}>
            <WhatsNew {...params} />
          </Layout>
        )}
      />     
      <Route
        render={() => (
          <ErrorPage code={404} message="Oops! Something is wrong." isSmallDevice={isSmallDevice} />
        )}
      />
    </Switch>
  );
};
