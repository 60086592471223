import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { IconButton } from "office-ui-fabric-react/lib/Button";
import Header from "../Shared/Header";
import SideNav from "./SideNav";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      isMenuOpen: true,
    };
    this.toggleNavbar = this.toggleNavbar.bind(this);
  }

  toggleNavbar = () => {
    this.setState((state) => ({
      showMenu: state.showMenu === undefined ? false : !state.showMenu,
    }));
  };

  toggleMenu = () => {
    this.setState((state) => ({
      showMenu: state.showMenu === undefined ? false : !state.showMenu,
    }));
  };

  render() {
    const { children, isSmallDevice } = this.props;
    const { showMenu } = this.state;

    return (
      <div className="app container">
        <main role="main">
          <div className="content">
            {isSmallDevice && (
              <div className="collpase-menu">
                <IconButton
                  className="navtogglebutton"
                  iconProps={{ iconName: "CollapseMenu" }}
                  onClick={this.toggleNavbar}
                />
              </div>
            )}
            <div className="left">
              <SideNav
                isSmallDevice={isSmallDevice}
                showMenu={showMenu}
                onMenuToggle={this.toggleNavbar}
                toggleMenu={this.toggleMenu}
              />
            </div>
            <div className="right">
              <Header {...this.props}></Header>
              <div className="container">
                {children}
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

export default withRouter(Layout);
