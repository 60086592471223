export const setItemInLocalStorage = (key, data) => {
  const base64EncodedData = window.btoa(unescape(encodeURIComponent(JSON.stringify(data))));
  localStorage.setItem(key, base64EncodedData);
};

export const getItemInLocalStorage = key => {
  const data = localStorage.getItem(key);  
  return data ? JSON.parse(decodeURIComponent(escape(window.atob(data)))) : null;
};

export const setItemInSessionStorage = (key, data) => {
  const base64EncodedData = window.btoa(unescape(encodeURIComponent(JSON.stringify(data))));
  sessionStorage.setItem(key, base64EncodedData);
};

export const getItemInSessionStorage = key => {
  const data = sessionStorage.getItem(key);  
  return data ? JSON.parse(decodeURIComponent(escape(window.atob(data)))) : null;
};
export const sessionKeys = {
  Authorize: 'teamshub.dc620867-cb38-4bf8-83a2-cc5d4ed962f6',
  ProdVersion: 'teamshub.e97bc3ec-a862-4064-9988-a4c531c09110',
  Theme : 'teamshub.7388d3cd-5e10-3006-820a-1fc55c4ec1ff'
}