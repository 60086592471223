export const postWebAPIResponseWithJSON = (apiUrl, data) => {
  return new Promise((resolve, reject) => {
    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Frame-Options': 'Deny',
      },
      body: data,
    })
    .then(response => {
      if (response.status === 401) {
        window.location.reload(true);
      }
      else {
        resolve(response.json());
      }
    })
    .catch(error => {
      if (error) {
        if(error.indexOf('401') > -1){
          window.location.reload(true);
        }
      }
      reject(error);
    });
  });
};

export const transformLinks = (links, roles) =>
  links.filter(({ show = [] }) => show.some(role => roles.indexOf(role) > -1))
  .map(({ name, url, isExpanded, key, links, icon}) => ({
    name,
    url,
    isExpanded,
    key : key ? icon : null,
    icon: icon ? icon : null,
    links: links ? transformLinks(links, roles) : null
  }));

  export const sendMail = (subject, content, attachments = null) => {
    return new Promise((resolve, reject) => {
      const body = JSON.stringify({
        subject: subject,
        body: content,
        attachments: attachments
      });
      postWebAPIResponseWithJSON(`api/mail/send`, body).then(response => {
        if (response.error && response.error.message) {
          reject(response.error.message);
        }
        resolve(response);
      })
      .catch(error => {
        reject(error)
      });
    });
  };