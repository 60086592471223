import React, { Component } from "react";
import { Routes } from './routes';
import { withResponsiveMode, ResponsiveMode } from 'office-ui-fabric-react/lib/utilities/decorators/withResponsiveMode';
import { HashRouter as Router } from 'react-router-dom';
import * as microsoftTeams from '@microsoft/teams-js';
import { connect } from 'react-redux'
import { getMSContext } from './actions/index'
import { authorizeTenant } from './helpers/auth'
import ErrorPage  from './containers/ErrorPage/ErrorPage'
import './styles/Global.css'
import './styles/Fluent.css';
import { registerIcons } from '@uifabric/styling';
import { AppSettings } from "./AppSettings";
import * as Storage from './helpers/storage';

registerIcons({
  icons: {
    'Play': <i className="fluent_play_24_regular"></i>,
    'Video': <i className="fluent_video_24_regular"></i>,
    'Documentation' : <i className="fluent_document_one_page_24_regular"></i>,
    'MapLayers' : <i className="fluent_lightbulb_20_regular"></i>,
    'HintText' : <i className="fluent_important_20_regular"></i>,
    'PostUpdate' : <i className="fluent_tasks_app_24_regular"></i>,
  }
});

class App extends Component {
  localContext = {tid: 'Dev', userPrincipalName: 'dev@dev.com', locale: 'en', role: "admin"}
  adminContext = {tid: window.location.href.split("tid")[1] !== undefined ? window.location.href.split("tid")[1].replace("?", "").replace("=", "") : "", userPrincipalName : '', locale: 'en', role: "admin"}
  isLocalEnv = () => {
    return window.location.origin.indexOf('localhost') > -1 
  }
  isAdminEnv = () => {
    return Storage.getItemInSessionStorage(Storage.sessionKeys.Authorize) || window.location.href.indexOf('tid') > -1
  }
  state = {
    contextLoaded: false,
    authorized: true
  }
  authorizeTenant = () => {
    try {
      const tid = this.props.context ? this.props.context.tid : null;
      if (tid) {
        authorizeTenant(tid).then(authorized => {
          this.setState({ authorized: authorized })
        })
      } else {
        this.setState({
          authorized: false
        });
      }
    } catch (error) {
      this.setState({
        authorized: false
      });
    }
  }
  clearCacheData = () => {
    caches.open('v1').then(function(cache) {
      cache.keys().then(function(keys) {
        keys.forEach(function(request, index, array) {
          cache.delete(request);
        });
      });
    });    
    if (caches) {
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }
    window.location.reload(true);
  };
  componentDidUpdate(prevPros) {
    if (this.props.context && prevPros.context && this.props.context.tid !== prevPros.context.tid) {
      this.authorizeTenant();
    }
  }
  componentDidMount() {
    const version = Storage.getItemInLocalStorage(Storage.sessionKeys.ProdVersion);
    if (!version || version !== AppSettings.ProdVersion) {
      this.clearCacheData();
      Storage.setItemInLocalStorage(Storage.sessionKeys.ProdVersion, AppSettings.ProdVersion);
    }
    if (this.isLocalEnv()) {
      this.props.getMSContext(this.localContext)
      this.setState({ contextLoaded: true }, () => {
        this.authorizeTenant();
        import(`./styles/Themes/default.css`);
      })
    }
    else if (this.isAdminEnv()) {
        this.props.getMSContext(this.adminContext)
        this.setState({ contextLoaded: true }, () => {
          this.authorizeTenant();
          Storage.setItemInSessionStorage(Storage.sessionKeys.Authorize, true)
          window.history.pushState(null, "", window.location.href.split("tid")[0].replace("?",""));
          import(`./styles/Themes/default.css`);
        })
    } else {
      microsoftTeams.initialize();
      var themeChanged = function (theme) {
        import(`./styles/Themes/${theme}.css`);
        Storage.setItemInLocalStorage(Storage.sessionKeys.Theme, theme)
      };
      microsoftTeams.registerOnThemeChangeHandler(themeChanged);
      microsoftTeams.getContext((context) => {
        this.props.getMSContext(context)
        this.setState({ contextLoaded: true }, () => {
          this.authorizeTenant();
          const theme = context.theme;
          import(`./styles/Themes/${theme}.css`);
          Storage.setItemInLocalStorage(Storage.sessionKeys.Theme, theme)
        })
      })
    }
  }
  render() {
    const { responsiveMode } = this.props;
    const { contextLoaded, authorized } = this.state;
    const isSmallDevice = responsiveMode < ResponsiveMode.xLarge;
    return contextLoaded ? authorized ?
      <Router basename={this.props.basename} history={this.props.history} hashType="noslash">
        <Routes authorized={authorized} isSmallDevice={isSmallDevice} />
      </Router> : <ErrorPage code={403} message="Access Denied" isSmallDevice={isSmallDevice} /> : <p></p>
  }
}

const mapStateToProps = state => ({
  context: state.app.context
})
const mapDispatchToProps = dispatch => ({
  getMSContext: (context) => dispatch(getMSContext(context))
})

export default withResponsiveMode(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(App),
);