import React from "react";
import "./ErrorPage.css";
const ErrorPage = ({ code, message }) => {
  return (
    <div className="no-match">
      <h1>{code}</h1>
      <p>{message}</p>
    </div>
  );
};

export default ErrorPage;
