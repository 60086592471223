import axios from "axios";

export const handleError = (error) => {
  const { status, message } = error;
  switch (status) {
    case 401:
      break;
    // do something when you're unauthenticated
    case 403:
      break;
    // do something when you're unauthorized to access a resource
    case 500:
      break;
    // do something when your server exploded
    default:
      break;
    // handle normal errors with some alert or whatever
  }
  return message; // I like to get my error message back
};
const SERVER_DOMAIN = "/api";
const getHeaders = () => {
  return {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
};
// HTTP GET Request - Returns Resolved or Rejected Promise
export const get = (path) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${SERVER_DOMAIN}${path}`, getHeaders())
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(handleError(error));
      });
  });
};
// HTTP PATCH Request - Returns Resolved or Rejected Promise
export const patch = (path, data) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(`${SERVER_DOMAIN}${path}`, data, getHeaders())
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(handleError(error));
      });
  });
};
// HTTP POST Request - Returns Resolved or Rejected Promise
export const post = (path, data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${SERVER_DOMAIN}${path}`, data, getHeaders())
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(handleError(error));
      });
  });
};
// HTTP DELETE Request - Returns Resolved or Rejected Promise
export const del = (path) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${SERVER_DOMAIN}${path}`, getHeaders())
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(handleError(error));
      });
  });
};
//HTTP GET with HEADER
export const getwithHeader = (path, header) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${path}`, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(handleError(error));
      });
  });
};
